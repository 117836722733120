import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import GettingStartedContent from '../../components/HelpCenter/GettingStarted/GettingStarted'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  HELP_CENTRE,
  HC_CONTESTS_GAMEPLAYS,
} from '../../components/internal-links'
import {
  generateCardsShowcaseVideoSchema,
  generateCasualShowcaseVideoSchema,
  generateTriviaShowcaseVideoSchema,
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Help Centre',
    url: HELP_CENTRE,
  },
  {
    title: 'Contests and Gameplay',
    url: HC_CONTESTS_GAMEPLAYS,
  },
]

const ContestsAndGamePlay: React.FC = () => {
  const [contestsAndGameplayFaqsSt, setContestsAndGameplayFaqsSt] = useState<
    Faq[]
  >([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(
      ({ contestsAndGameplayFaqs }) =>
        setContestsAndGameplayFaqsSt(contestsAndGameplayFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="Contests & Gameplays: Get Help on Contests & Games on Mega App"
        description="Visit our contests and gameplays help centre for answers to queries on rules on various on Mega, how to play a contest, what is a buy-in, etc. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(contestsAndGameplayFaqsSt)}
        videoSchema={[
          generateCardsShowcaseVideoSchema(),
          generateCasualShowcaseVideoSchema(),
          generateTriviaShowcaseVideoSchema(),
        ]}
      />
      <GettingStartedContent pageIndex={3} faqs={contestsAndGameplayFaqsSt} />
    </Layout>
  )
}

export default ContestsAndGamePlay
